function parseClone(obj) {
    return JSON.parse(JSON.stringify(obj));
}

function Get_Fields(locale, models) {
    return new Promise((resolve, reject) => {
        models
            .fields
            .aggregate([
                {
                    $match: {status: "1"}
                },
                {
                    $lookup: {
                        from: "fields_options",
                        localField: "id",
                        foreignField: "field_id",
                        as: "field_options"
                    }
                },
                {
                    $project: {
                        _id: 0,
                        id: 1,
                        default_label: 1,
                        attribute_code: 1,
                        is_required: 1,
                        type: 1,
                        validation: 1,
                        position: 1,
                        display_form: 1,
                        status: 1,
                        field_options: {
                            $map: {
                                input: "$field_options",
                                as: "option",
                                in: {
                                    id: "$$option.id",
                                    label: {
                                        $arrayElemAt: [
                                            "$$option.translations.label",
                                            { $indexOfArray: ["$$option.translations.locale", locale] }
                                        ]
                                    }
                                }
                            }
                        }
                    }
                }
            ])
            .then((res) => {
                resolve(parseClone(res));
            })
            .catch((err) => reject(err));
    });
}

module.exports = {
    Get_Fields,
}