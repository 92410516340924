/*** getting menu with pages together ***/
const menuAggregate = (locale) => {
    return [
        {
            $unwind: {
                path: "$translations",
                preserveNullAndEmptyArrays: true,
            }
        },
        {
            $match: {
                "translations.locale": locale
            }
        },
        {
            $lookup: {
                from: "pages",
                let: {pageId: "$page_id"},
                pipeline: [
                    {
                        $match: {
                            $expr: {
                                $or: [
                                    {$eq: ["$id", "$$pageId"]},
                                    {$eq: ["$$pageId", null]}
                                ]
                            }
                        }
                    },
                    {
                        $unwind: {
                            path: "$translations",
                            preserveNullAndEmptyArrays: true
                        }
                    },
                    {
                        $match: {
                            "translations.locale": locale
                        }
                    }
                ],
                as: "pages"
            }
        },
        {
            $unwind: {
                path: "$pages",
                preserveNullAndEmptyArrays: true
            }
        },
        {
            $unwind: {
                path: "$pages.translations",
                preserveNullAndEmptyArrays: true
            }
        },
        {
            $match: {
                "pages.translations.locale": locale
            }
        },
        {
            $group: {
                _id: "$_id",
                id: {"$first": "$id"},
                position: {"$first": "$position"},
                layout: {"$first": "$layout"},
                created_at: {"$first": "$created_at"},
                updated_at: {"$first": "$updated_at"},
                parent_id: {"$first": "$parent_id"},
                page_id: {"$first": "$page_id"},
                _lft: {"$first": "$_lft"},
                _rgt: {"$first": "$_rgt"},
                type: {"$first": "$type"},
                new_tab: {"$first": "$new_tab"},
                m_id: {"$first": "$translations.id"},
                locale: {"$first": "$translations.locale"},
                name: {"$first": "$translations.name"},
                url_key: {"$first": "$translations.url_key"},
                status: {"$first": "$translations.status"},
                children: {"$first": "$translations.children"},
                cms_menu_id: {"$first": "$translations.cms_menu_id"},
                content: {"$first": "$pages.content"},
                meta_description: {"$first": "$pages.translations.meta_description"},
                meta_title: {"$first": "$pages.translations.meta_title"},
                meta_keywords: {"$first": "$pages.translations.meta_keywords"},
                page_title: {"$first": "$pages.translations.page_title"},
                slug: {"$first": "$pages.translations.url_key"},
                p_id: {"$first": "$pages.translations.id"},
                html_content: {"$first": "$pages.translations.html_content"},
                cms_page_id: {"$first": "$pages.translations.cms_page_id"},
                p_layout: {"$first": "$pages.layout"},
            }
        },
        {
            $project: {
                _id: 0
            }
        },
        {
            $match: {
                $or: [
                    {status: 1},
                    {status: "1"},
                ]
            }
        },
        {
            $sort: {
                position: 1,
                created_at: 1
            }
        }
    ]
}

/*** getting categories ***/
const categoryAggregate = (locale) => {
    return [
        {
            $unwind: {
                path: "$translations",
                preserveNullAndEmptyArrays: true,
            }
        },
        {
            $match: {
                "translations.locale": locale
            }
        },
        {
            $lookup: {
                from: "yoast_categories",
                localField: "id",
                foreignField: "category_id",
                as: "yoast_categories",
                pipeline: [
                    {
                        $match: {
                            $expr: {
                                $eq: ["$locale", locale]
                            }
                        }
                    }
                ]
            }
        },
        {
            $group: {
                _id: "$_id",
                id: {"$first": "$id"},
                position: {"$first": "$position"},
                image: {"$first": "$image"},
                status: {"$first": "$status"},
                _lft: {"$first": "$_lft"},
                _rgt: {"$first": "$_rgt"},
                parent_id: {"$first": "$parent_id"},
                created_at: {"$first": "$created_at"},
                updated_at: {"$first": "$updated_at"},
                display_mode: {"$first": "$display_mode"},
                category_icon_path: {"$first": "$category_icon_path"},
                additional: {"$first": "$additional"},
                c_id: {"$first": "$translations.id"},
                name: {"$first": "$translations.name"},
                slug: {"$first": "$translations.slug"},
                description: {"$first": "$translations.description"},
                meta_title: {"$first": "$translations.meta_title"},
                meta_description: {"$first": "$translations.meta_description"},
                meta_keywords: {"$first": "$translations.meta_keywords"},
                category_id: {"$first": "$translations.category_id"},
                locale: {"$first": "$translations.locale"},
                locale_id: {"$first": "$translations.locale_id"},
                url_path: {"$first": "$pages.url_path"},
                yoast: {"$first": "$yoast_categories"}
            }
        },
        {
            $project: {
                _id: 0
            }
        },
        {
            $match: {
                $or: [
                    {status: 1},
                    {status: "1"},
                ]
            }
        },
        {
            $sort: {
                position: 1,
                created_at: 1
            }
        }
    ]
}

/*** getting featured or new products ***/

const featuredOrNewAggregate = (field, locale, limit) => {
    return [
        {
            $lookup: {
                from: "product_flat",
                localField: "id",
                foreignField: "product_id",
                as: "product_flat",
                pipeline: [
                    {
                        $match: {
                            $and: [
                                {[field]: 1},
                                {locale: locale},
                                {parent_id: null}
                            ]
                        }
                    }
                ]
            }
        },
        {
            $lookup: {
                from: "product_inventories",
                localField: "id",
                foreignField: "product_id",
                as: "product_inventories"
            }
        },
        {
            $lookup: {
                from: "product_images",
                localField: "id",
                foreignField: "product_id",
                as: "product_images",
                pipeline: [
                    {
                        $project: {
                            _id: 1,
                            id: 1,
                            type: 1,
                            path: 1,
                            product_id: 1
                        }
                    }
                ]
            }
        },
        {
            $lookup: {
                from: "attributes",
                as: "block_title",
                pipeline: [
                    {
                        $match: {code: field}
                    }
                ]
            }
        },
        {
            $unwind: {
                path: "$block_title",
                preserveNullAndEmptyArrays: true
            }
        },
        {
            $unwind: {
                path: "$block_title.translations",
                preserveNullAndEmptyArrays: true
            }
        },
        {
            $match: {
                "block_title.translations.locale": locale,
                [`product_flat.${field}`]: 1
            }
        },
        {
            $limit: limit
        },

        {
            $group: {
                _id: "$_id",
                id: {"$first": "$id"},
                sku: {"$first": "$sku"},
                type: {"$first": "$type"},
                parent_id: {"$first": "$parent_id"},
                attribute_family_id: {"$first": "$attribute_family_id"},
                additional: {"$first": "$additional"},
                updated_at: {"$first": "$updated_at"},
                created_at: {"$first": "$created_at"},
                product_flat: {"$first": "$product_flat"},
                product_inventories: {"$first": "$product_inventories"},
                product_images: {"$first": "$product_images"},
                block_title: {"$first": "$block_title.translations.name"},
                min_price: {$first: {$arrayElemAt: ["$product_flat.min_price", 0]}},
                special_price: {$first: {$arrayElemAt: ["$product_flat.special_price", 0]}},
                special_price_from: {$first: {$arrayElemAt: ["$product_flat.special_price_from", 0]}},
                special_price_to: {$first: {$arrayElemAt: ["$product_flat.special_price_to", 0]}},
                price: {$first: {$arrayElemAt: ["$product_flat.price", 0]}},
                status: {$first: {$arrayElemAt: ["$product_flat.status", 0]}},
            }
        },
        {
            $addFields: {
                special_price_expired: {
                    $cond: {
                        if: {
                            $and: [
                                {$ne: ["$special_price_from", null]},
                                {$ne: ["$special_price_to", null]},
                                {$lt: ["$special_price_to", new Date()]}
                            ]
                        },
                        then: true,
                        else: false
                    }
                },
            }
        },
        {
            $project: {
                _id: 0,
                id: 1,
                sku: 1,
                type: 1,
                parent_id: 1,
                attribute_family_id: 1,
                additional: 1,
                updated_at: 1,
                created_at: 1,
                product_flat: 1,
                product_inventories: 1,
                product_images: 1,
                block_title: 1,
                min_price: 1,
                special_price: 1,
                special_price_from: 1,
                special_price_to: 1,
                special_price_expired: 1,
                price: 1,
                status: 1
            }
        },
        {
            $match: {
                $or: [
                    {status: 1},
                    {status: "1"},
                ]
            }
        },
        {
            $sort: {
                position: 1,
                created_at: 1
            }
        }
    ]
}

module.exports = {
    menuAggregate,
    categoryAggregate,
    featuredOrNewAggregate,
}