function Get_Page_By_Slug({pageSlug, locale}, models) {
    return new Promise(async (resolve, reject) => {
        const search =
            typeof parseInt(pageSlug) == "number" && parseInt(pageSlug)
                ? {id: parseInt(pageSlug)}
                : {url_key: pageSlug};
        const pages = new Promise( (resolve1, reject1) => {
            models
                .pages
                .find()
                .then((item) => {
                    const translatedData = item.map(el => {
                        const findPageItemItl = el?.translations?.find((translate) => {
                            if (translate.locale === locale && translate.url_key === search.url_key) {
                                return JSON.parse(JSON.stringify(translate));
                            }
                        })
                        let translated;
                        if (findPageItemItl) {
                            translated = { cms_page_id: el.id, image: el?.image, ...findPageItemItl }
                        }

                        if (translated?.html_content.includes("SmartSearch")) {

                            let start = translated?.html_content.indexOf("&lt;")
                            let end = translated?.html_content.indexOf("/&gt;")
                            let shortCode = translated?.html_content.slice(start - 2, end)

                            let shortCodeActions = shortCode.split(" ")
                            let shortCodeId = shortCodeActions[1]?.slice(shortCodeActions[1]?.indexOf("=") + 1)
                            let step1_title = shortCodeActions[2]?.slice(shortCodeActions[2]?.indexOf("=") + 1)
                            let step2_title = shortCodeActions[3]?.slice(shortCodeActions[3]?.indexOf("=") + 1)
                            let step3_title = shortCodeActions[4]?.slice(shortCodeActions[4]?.indexOf("=") + 1)
                            let new_search = shortCodeActions[5]?.slice(shortCodeActions[5]?.indexOf("=") + 1)
                            return {
                                ...translated,
                                html_content: translated.html_content.replace(shortCode, ""),
                                hasShortCode: true,
                                shortCodeId: shortCodeId,
                                step1_title,
                                step2_title,
                                step3_title,
                                new_search
                            }
                        }
                        return translated
                    })
                    const newTranslatedData = translatedData?.filter(function (element) {
                        return element !== undefined;
                    });
                    resolve1(newTranslatedData);
                })
                .catch((err) => reject(err));
        })
        const pageYoast =  new Promise(async (resolve2, reject2) => {
            const findCurrYoast = await pages
            models
                .yoast_pages
                .find({page_id: findCurrYoast[0].cms_page_id, locale})
                .then((item) => resolve2(item))
        })
        const parsedPages = await pages;
        const parsedYoast = await pageYoast;
        resolve({ data: parseClone(parsedPages), yoast: parseClone(parsedYoast) || null })
    });
}


/********************** new code ****************************/
function parseClone(obj) {
    return JSON.parse(JSON.stringify(obj));
}

function Get_Channels(locale, models) {
    return new Promise((resolve, reject) => {
        models
            .channels
            /*TODO check whether we need locale here or not*/
            // .find({locale: {'$ne': locale}})
            .find()
            .then((response) => resolve(parseClone(response)))
            .catch((err) => reject(err));
    })
}

function Get_Pages(locale, models) {
    return new Promise((resolve, reject) => {
        models
            .pages
            .find()
            .then((res) => {
                const filteredPages = res.map((translationArr) => translationArr.translations.find((item) => item.locale === locale));
                resolve(parseClone(filteredPages))
            })
            .catch((err) => reject(err));
    })
}

module.exports = {
    Get_Channels,
    Get_Page_By_Slug,
    Get_Pages,
};