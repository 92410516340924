const parseClone = (obj) => {
    return JSON.parse(JSON.stringify(obj));
}

const buildTree = (array, parentId) => {
    let children = []
    for (let i = 0, length = array.length; i < length; i++) {
        if (array[i].parent_id === parentId) {
            let child = array[i]
            child.children = buildTree(array, child.id)
            children.push(child)
        }
    }
    return children
}

const makeImageClone = (path) => {
    return {
        path: path,
        url: `/storage/${path}`,
        large_image_url: `/cache/large/${path}`,
        medium_image_url: `/cache/medium/${path}`,
        small_image_url: `/cache/small/${path}`,
        original_image_url: `/cache/original/${path}`,
    };
}

const arrayConvertToObject = (response) => {
    return response.reduce((prev, next) => {
        const keys = Object.keys(next);
        if (keys.length > 1) {
            const array = keys.reduce((a, n) => {
                return {
                    ...a, [n]: next[n],
                };
            }, {});
            return {...prev, ...array};
        } else {
            return {...prev, [keys]: next[keys]};
        }
    }, {});
}

const convertDateToSeconds = (date = null, currentDate = false) => {
    let dateObj = new Date(date)
    let currDateObj = new Date()

    return currentDate ? currDateObj.getTime() / 1000 : dateObj.getTime() / 1000;
}

const defaultFilter = ({key, options}) => {

    let dynamicSearchKey;
    switch (typeof options[key]) {
        case "boolean":
            dynamicSearchKey = "boolean_value";
            break;
        default:
            let isNum = /^\d+$/.test(options[key]);
            switch (isNum) {
                case true:
                    dynamicSearchKey = "integer_value";
                    break;
                default:
                    dynamicSearchKey = "text_value";
                    break;
            }
    }

    if (options.isSearch) {
        delete options.isSearch;
        let value;
        if (options[key].includes(",")) {
            dynamicSearchKey = "integer_value";
            value = options[key].split(",");
        } else {
            value = options[key];
        }
        return {[dynamicSearchKey]: value};
    } else {
        return {}
    }
}

const findMinimalOrMaximalPrice = (obj, order) => {
    const START_DATE = "1970-01-01";

    if (obj) {
        const {price, min_price, max_price, special_price} = obj
        let newDate = new Date();
        let pricesArr
        let date_from = obj.special_price_from
        let date_to = obj.special_price_to
        const date_now = newDate;
        if (special_price && date_now >= date_from && date_now <= date_to) {
            pricesArr = [price, min_price, max_price, special_price]
        } else if (special_price && date_from === START_DATE && date_to === START_DATE) {
            pricesArr = [price, min_price, max_price, special_price]
        } else {
            pricesArr = [price, min_price, max_price]
        }

        if (price) {
            if (order === 1) {
                return pricesArr?.filter(el => el !== null).sort((a, b) => b - a)[0]
            } else {
                return pricesArr?.filter(el => el !== null).sort((a, b) => a - b)[0]
            }
        }
    }

}

module.exports = {
    parseClone,
    buildTree,
    makeImageClone,
    arrayConvertToObject,
    convertDateToSeconds,
    defaultFilter,
    findMinimalOrMaximalPrice,
}