const buildData = (res, locale) => {

    let translatedData = []
    res.map((blog) => {
        let translated = blog?.translations?.find((translate) => {
            if (translate.locale === locale) {
                const localed = JSON.parse(JSON.stringify({
                    ...translate,
                    image: blog.image,
                    created_at: blog.created_at,
                    published_at: blog.published_at
                }));
                translatedData?.push(localed);
            }
        });
        translatedData?.push(translated);
    });
    return translatedData?.filter(el => el);
}

function Get_All_Posts({locale: locale, page = 1, limit = 6}, models) {
    return new Promise((resolve) => {
        models
            .posts
            .countDocuments({})
            .exec((count_error, count) => {
                if (count_error) {
                    let res;
                    return res.json(count_error);
                }
                const pageCount = Math.ceil(count / limit);
                const skip = (+page - 1) * limit;
                models
                    .posts
                    .find().sort([['_id', -1]])
                    .skip(skip)
                    // .limit(+limit)
                    .exec((err, _blogs) => {

                        resolve({
                            data: buildData(_blogs, locale),
                            meta: {
                                current_page: page,
                                total: pageCount,
                            },
                            links: {},
                        });
                    })
            })
    });
}


function Get_Post_By_Slug({locale: locale, slug}, models) {
    return new Promise((resolve, reject) => {
        models
            .posts
            .findOne({slug})
            .then((post) => {
                let translatedData = [];
                post?.translations.map((item) => {
                    if (item.locale === locale) {
                        const locale = JSON.parse(JSON.stringify({
                            ...item,
                            image: post.image,
                            created_at: post.created_at,
                            published_at: post.published_at
                        }));
                        translatedData.push(locale);
                    }
                });
                const modified = translatedData?.filter(element => element)
                resolve({data: modified});
            })
            .catch((err) => reject(err));
    });
}

function Get_Posts_Categories({locale},models){
    return new Promise((resolve, reject) => {
        models
            .post_categories
            .find()
            .then((postCategories) => {


                resolve(postCategories);
            }).catch(err => reject(err))

    })
}


function Get_BLogs_By_Category_Slug({slug, locale}, models) {
    if (slug === "undefined") {
        return Get_All_Posts({locale}, models)
    } else {
        return new Promise((resolve, reject) => {
            models
                .post_categories
                .findOne({slug})
                .then((blog) => {
                    let catID = blog.id
                    let translated = blog.translations.find(el => el.locale === locale)
                    let catSlug = translated?.name
                    let metas = {
                        meta_title :translated?.meta_title,
                        meta_description:translated?.meta_description,
                        meta_keywords:translated?.meta_keywords,
                    }
                    return new Promise((resolve2, reject2) => {
                        models
                            .post_categories_posts
                            .aggregate(
                                [
                                    {
                                        $lookup: {
                                            from: "posts",
                                            localField: "blog_post_id",
                                            foreignField: "id",
                                            as: "post",

                                        },
                                    },
                                    {
                                        $match: {
                                            blog_category_id: catID
                                        }
                                    }

                                ]).then(res => {
                            let posts  = res.map(postData => postData.post).flat()
                            resolve({data: [...buildData(posts, locale)],title:catSlug,metas});
                        }).catch(err => reject2(err))
                    })
                    // resolve({data: newTranslatedData});
                }).catch(err => reject(err))
        })
    }
}


module.exports = {
    Get_All_Posts,
    Get_Post_By_Slug,
    Get_Posts_Categories,
    Get_BLogs_By_Category_Slug,
};